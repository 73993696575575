.container.testimonials_container{
    width: 40%;
    padding-bottom: 4rem;
}
.client_avatar{
    border-radius: 1.5rem;
    overflow: hidden;
    width: 80%;
    margin: 0 auto 1rem;
    border: 0.3rem solid var(--color-primary-variant);
 }

.swiper-pagination-clickble .swiper-pagination-bullet{
    background: var(--color-primary);

}
   /* ========== MEDIA QUERIES (MEDIUM DEVICE)============*/

   @media screen and (max-width:1024px){
    .container.testimonials_container{
        width: 60%;
    }


 }
  /* ========== MEDIA QUERIES (SMALL DEVICE)============*/

  @media screen and (max-width:600px){  
    
    .container.testimonials_container{
        width: var(--container-width-sm);
    }
    .client_review{
        width: var(--container-width-sm);
    }


  }















